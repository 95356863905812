import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { useAuth } from "../contexts/UserContext";

import Main from "../components/Main";
import Erro404 from "../pages/Erro404";

import { routesDefault, routesPatient, routesDoctor } from "./routeConfig";
import { UserType } from "../store/types/UserType";

type PrivateRouteProps = {
  component: React.FC;
  path: string;
  exact: boolean;
};

export default function Routes() {
  const { isAutenticated, user } = useAuth();

  const PrivateRoute = ({ component, path, exact }: PrivateRouteProps) => {
    return isAutenticated() ? (
      <Main>
        <Route path={path} exact={exact} component={component} />
      </Main>
    ) : (
      <Redirect to="/" />
    );
  };

  return (
    <Router>
      <Switch>
        {Object.keys(user).length === 0 &&
          routesDefault.map((route, index) =>
            route.auth ? (
              <PrivateRoute
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ) : (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            )
          )}

        {Object.keys(user).length !== 0 &&
          user.type === UserType.PATIENT &&
          routesPatient
            .concat(routesDefault)
            .map((route, index) =>
              route.auth ? (
                <PrivateRoute
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ) : (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              )
            )}

        {Object.keys(user).length !== 0 &&
          user.type === UserType.DOCTOR &&
          routesDoctor
            .concat(routesDefault)
            .map((route, index) =>
              route.auth ? (
                <PrivateRoute
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              ) : (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={route.component}
                />
              )
            )}
        <Route path="*">
          <Erro404 />
        </Route>
      </Switch>
    </Router>
  );
}
