import logo from "../assets/img/logo.png";

export default function Erro404() {
  return (
    <div className="w-screen h-screen bg-blue-10 flex flex-col items-center overflow-hidden">
      <img src={logo} alt="Examine" className="w-64 mt-20 2xl:mt-40" />
      <h1
        className="font-extrabold font-sans text-blue-100 opacity-25"
        style={{ fontSize: "14rem" }}
      >
        404
      </h1>
      <p className="text-gray-80 text-xl -mt-7 font-medium font-exo">
        Página não encontrada
      </p>
      <p className="text-gray-80 mt-2">
        A página que você está procurando não existe ou foi removida.
      </p>
    </div>
  );
}
