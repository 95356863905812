import Home from "../pages/Home";
import Login from "../pages/Login";
import ForgotPass from "../pages/ForgotPass";
import ResetPass from "../pages/ResetPass";
import Register from "../pages/Register";

// Patients
import HomePatient from "../pages/patients";

// Doctor
import HomeDoctor from "../pages/doctors";
import DoctorPatientsList from "../pages/doctors/patients/List";
import DoctorPatientsShow from "../pages/doctors/patients/Show";

type Route = {
  path: string;
  auth: boolean;
  component: React.FC;
  exact: boolean;
};

export const routesDefault: Route[] = [
  {
    path: "/",
    exact: true,
    auth: false,
    component: Home,
  },
  {
    path: "/entrar/:user",
    exact: true,
    auth: false,
    component: Login,
  },
  {
    path: "/recuperar/senha/:user",
    exact: true,
    auth: false,
    component: ForgotPass,
  },
  {
    path: "/alterar/senha/:user",
    exact: true,
    auth: false,
    component: ResetPass,
  },
  {
    path: "/registro",
    exact: true,
    auth: false,
    component: Register,
  },
];

export const routesPatient: Route[] = [
  {
    path: "/paciente",
    exact: true,
    auth: true,
    component: HomePatient,
  },
  {
    path: "/paciente/exames",
    exact: true,
    auth: true,
    component: HomePatient,
  },
];

export const routesDoctor: Route[] = [
  {
    path: "/medico",
    exact: true,
    auth: true,
    component: HomeDoctor,
  },
  {
    path: "/medico/exames",
    exact: true,
    auth: true,
    component: HomeDoctor,
  },
  {
    path: "/medico/pacientes",
    exact: true,
    auth: true,
    component: DoctorPatientsList,
  },
  {
    path: "/medico/pacientes/:patientId",
    exact: true,
    auth: true,
    component: DoctorPatientsShow,
  },
];
