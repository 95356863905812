// import React from "react";
// import ReactNotification from "react-notifications-component";
import { UserProvider } from "./contexts/UserContext";
import Routes from "./routes";

// Notification
// import "react-notifications-component/dist/theme.css";

// Theme
import "./theme/examine.css";

// Tailwind
import "./assets/style/tailwind.css";

// My CSS
import "./assets/style/custom.css";

// Primereact
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import PrimeReact from "primereact/api";
PrimeReact.ripple = true;

export default function App() {
  return (
    <UserProvider>
      <div className="App">
        {/* <ReactNotification /> */}
        <Routes />
      </div>
    </UserProvider>
  );
}
