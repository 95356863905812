import { CardLastExamResult, CardRecentExams } from "../../components/Card";

export default function Patient() {
  return (
    <div className="col-span-10 col-start-2">
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-12 text-gray-80 my-8">
          <h2 className="font-exo text-xl ">Bom dia, Sarah</h2>
          <p className="text-sm">
            Aqui está o seu resumo de atividades até o dia{" "}
            <strong>28/05/2020</strong>
          </p>
        </div>

        <div className="col-span-12 lg:col-span-8 mb-8 lg:mb-0">
          <CardLastExamResult />
        </div>
        <div className="col-span-12 lg:col-span-3 lg:col-start-10">
          <CardRecentExams />
        </div>
      </div>
    </div>
  );
}
