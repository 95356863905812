import { apiAxios } from "../utils/axios";
import { urls } from "../utils/urls";

import { User } from "../store/types/UserType";
import { AuthData } from "../store/types/Auth";

export const UserServices = {
  signUp: async (user: User) => {
    const response = await apiAxios.post(`${urls.USER}/signup`, user);
    return response;
  },

  login: async (values: AuthData) => await apiAxios.post(`${urls.USER}/login`, values),

  forgotPassword: async (values: { email: string }) => {
    const response = await apiAxios.post(`${urls.USER}/forgotpass`, values);
    return response;
  },

  resetPassword: async (values: {
    token: string;
    email: string;
    password: string;
  }) => {
    const response = await apiAxios.post(
      `${urls.USER}/resetpass/${values.token}`,
      {
        email: values.email,
        password: values.password,
      }
    );
    return response;
  },

  home: async () => {
    const response = await apiAxios.get(`${urls.USER}/home`);
    return response;
  },
};
