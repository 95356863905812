import { ReactNode, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { useAuth } from "../contexts/UserContext";

import Sidebar from "./Sidebar";

// import { InputText } from "primereact/inputtext";
import { Badge } from "primereact/badge";
import { Avatar } from "primereact/avatar";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

import avatar from "../assets/img/avatar.png";

type MainProps = {
  children: ReactNode;
};

export default function Main({ children }: MainProps) {
  const { user, logout } = useAuth();
  const history = useHistory();
  const menu = useRef<any>();
  // const [search, setSearch] = useState<string>("");

  const userMenu = [
    // { label: "Meu perfil", icon: "pi pi-user text-xs" },
    // { label: "Alterar senha", icon: "pi pi-lock text-xs" },
    { label: "Sair", icon: "pi pi-sign-out text-xs", command: () => exit() },
  ];

  const exit = () => {
    logout();
    history.push("/");
  };

  return (
    <div className="grid grid-cols-9">
      <Sidebar />

      <div className="col-span-9 md:col-span-8 bg-blue-10 h-screen overflow-y-auto pb-16 pt-8">
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-10 col-start-2 lg:col-start-2 lg:col-span-7 p-fluid">
          <h2 className="font-exo text-xl my-4">Olá, Dr(a) {user.firstName}</h2>
            {/* <span className="p-input-icon-left">
              <i className="pi pi-search" style={{ color: "#aaa" }} />
              <InputText
                value={search}
                onChange={(e: any) => setSearch(e.target.value)}
                placeholder="Pesquise aqui"
                className="p-inputtext-sm bg-white"
              />
            </span> */}
          </div>

          <div className="col-span-10 col-start-2 lg:col-span-3 flex items-center lg:justify-end mt-3 lg:mt-0">
            {/* <i className="pi pi-bell p-overlay-badge p-mr-4">
              <Badge severity="info" />
            </i> */}

            {/* <Avatar image={avatar} className="p-mr-2" shape="circle" /> */}

            <Menu model={userMenu} popup ref={menu} className="text-xs p-0" />
            <Button
              label={`${user.firstName} ${user.lastName}`}
              icon="pi pi-angle-down"
              iconPos="right"
              className="button-menu-user"
              onClick={event => menu.current.toggle(event)}
            />

            {/* <div className="text-gray-80 font-medium text-sm flex items-center cursor-pointer">
              {}
              <i className="pi pi-angle-down text-xs ml-1 mt-1"></i>
            </div> */}
          </div>

          {children}
        </div>
      </div>
    </div>
  );
}
