import { useState } from "react";
import { createContext, useContext, ReactNode } from "react";
import jwtDecode from "jwt-decode";

export const TOKEN = "@examine:token";
export const USER = "@examine:user";

type DecodedToken = {
  key: string;
  iat: number;
  exp: number;
};
export interface UserSession {
  type: string;
  lastName: string;
  firstName: string;
}

type UserContextData = {
  login: (token: string) => void;
  logout: () => void;
  isAutenticated: () => boolean;
  setSessionUser: (user: UserSession) => void;
  user: any;
};

type UserContextProviderProps = {
  children: ReactNode;
};

export const UserContext = createContext({} as UserContextData);

export function UserProvider({ children }: UserContextProviderProps) {
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem(USER) || "{}")
  );

  const login = async (token: string) => {
    sessionStorage.setItem(TOKEN, token);
  };

  const logout = () => {
    sessionStorage.removeItem(TOKEN);
    sessionStorage.removeItem(USER);
  };

  const isAutenticated = () => {
    const token = sessionStorage.getItem(TOKEN);

    if (token) {
      return isValidToken(token);
    }

    return false;
  };

  const isValidToken = (token: string) => {
    if (!token) {
      return false;
    }
    const decodedToken: DecodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp > currentTime;
  };

  const setSessionUser = (user: UserSession) => {
    sessionStorage.setItem(USER, JSON.stringify(user));
    setUser(user);
  };

  return (
    <UserContext.Provider
      value={{
        login,
        logout,
        isAutenticated,
        setSessionUser,
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(UserContext);
};
