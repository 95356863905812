import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useHistory, useParams } from "react-router-dom";

import { useAuth } from "../contexts/UserContext";
import { UserServices } from "../services/userServices";

import { validatePasswordCharacterUpper } from "../utils/validators";

import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";

import { BsArrowLeft } from "react-icons/bs";

import logo from "../assets/img/logo.png";
import success from "../assets/img/success.svg";

type User = {
  token: string;
  email: string;
  password: string;
  confirmPassword: string;
};

type ParamsRouter = {
  user: string;
};

const initialValues: User = {
  token: "",
  email: "",
  password: "",
  confirmPassword: "",
};

export const validationSchema = Yup.object().shape({
  token: Yup.string().trim().required("Campo obrigatório"),
  email: Yup.string().email("Email inválido").required("Campo obrigatório"),
  password: Yup.string().required("Campo obrigatório"),
  confirmPassword: Yup.string().required("Campo obrigatório"),
});

export default function LoginDoctor() {
  const history = useHistory();
  const { user } = useParams<ParamsRouter>();
  const [validations, setValidations] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [alterPass, setAlterPass] = useState<boolean>(false);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values: User) => {
      if (values.password !== values.confirmPassword) {
        setValidations(false);
      } else {
        setValidations(true);

        const { status } = await UserServices.resetPassword({
          token: values.token,
          email: values.email,
          password: values.password,
        });

        if (status === 200) {
          setAlterPass(true);
        } else {
          formik.errors.token = "Token inválido";
        }
      }
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const verifyPasswordMinimumLength = () => {
    const { password, confirmPassword } = formik.values;

    if (!password && !confirmPassword) {
      return "text-gray-20 border-gray-20";
    } else if (password.length < 8 || confirmPassword.length < 8) {
      return "text-red-600 border-red-600";
    } else {
      return "text-blue-100 border-blue-100";
    }
  };

  const verifyPasswordCaracterUpper = () => {
    const { password, confirmPassword } = formik.values;

    if (!password && !confirmPassword) {
      return "text-gray-20 border-gray-20";
    } else if (
      validatePasswordCharacterUpper(password) &&
      validatePasswordCharacterUpper(confirmPassword)
    ) {
      return "text-blue-100 border-blue-100";
    } else {
      return "text-red-600 border-red-600";
    }
  };

  const headerCard = (
    <div className="bg-blue-100 text-white py-3 px-7 rounded-t-md">
      <h1 className="font-exo-light">ALTERAR</h1>
      <h1 className="font-exo">SENHA</h1>
    </div>
  );

  return (
    <div className="w-screen h-screen flex flex-col items-center bg-blue-10 p-5">
      <img src={logo} alt="Examine" className="w-64 mt-9 2xl:mt-40" />

      <div className="w-full grid grid-cols-12 2xl:grid-cols-11 mt-10">
        <div className="col-span-12 lg:col-start-5 lg:col-span-4 2xl:col-start-5 2xl:col-span-3">
          <Card header={headerCard} className="shadow rounded-md">
            {alterPass ? (
              <div className="grid grid-cols-12">
                <div className="col-span-12">
                  <img
                    src={success}
                    alt="Senha Alterada"
                    width="70"
                    className="mx-auto mt-4"
                  />
                  <h2 className="text-center mt-2 mb-1 font-exo text-xl text-gray-80">
                    Senha alterada
                  </h2>
                  <p className="text-center text-gray-80 text-sm">
                    Voce já pode acessar a plataforma. Vamos lá?
                  </p>
                </div>

                <div className="col-start-5 col-span-4 p-fluid mt-8">
                  <Button
                    label="Entrar"
                    onClick={_ => history.push(`/entrar/${user}`)}
                  />
                </div>
              </div>
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <div className="grid grid-cols-12 gap-x-3 px-5">
                  <div className="col-span-12 p-fluid p-field">
                    <span className="p-input-icon-left">
                      <i className="pi pi-key" />
                      <InputText
                        name="token"
                        type="text"
                        value={formik.values.token}
                        onChange={formik.handleChange}
                        placeholder="Token"
                        className={`p-inputtext-sm ${
                          formik.errors.token && "p-invalid"
                        }`}
                      />
                    </span>
                    {formik.errors.token && (
                      <span className="p-error text-xs">
                        {formik.errors.token}
                      </span>
                    )}
                  </div>

                  <div className="col-span-12 p-fluid p-field">
                    <span className="p-input-icon-left">
                      <i className="pi pi-user" />
                      <InputText
                        name="email"
                        type="text"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        placeholder="Email"
                        className={`p-inputtext-sm ${
                          formik.errors.email && "p-invalid"
                        }`}
                      />
                    </span>
                    {formik.errors.email && (
                      <span className="p-error text-xs">
                        {formik.errors.email}
                      </span>
                    )}
                  </div>

                  <div className="col-span-12 p-fluid p-field">
                    <span className="p-input-icon-left p-input-icon-right">
                      <i className="pi pi-lock" />
                      <InputText
                        name="password"
                        type={showPassword ? "text" : "password"}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        placeholder="Senha"
                        className={`p-inputtext-sm ${
                          (formik.errors.password || !validations) &&
                          "p-invalid"
                        }`}
                      />
                      <i
                        className={
                          showPassword ? "pi pi-eye-slash" : "pi pi-eye"
                        }
                        onClick={_ => setShowPassword(!showPassword)}
                      />
                    </span>
                    {formik.errors.password && (
                      <span className="p-error text-xs">
                        {formik.errors.password}
                      </span>
                    )}
                  </div>

                  <div className="col-span-12 p-fluid p-field">
                    <span className="p-input-icon-left p-input-icon-right">
                      <i className="pi pi-lock" />
                      <InputText
                        name="confirmPassword"
                        type={showConfirmPassword ? "text" : "password"}
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        placeholder="Confirmar senha"
                        className={`p-inputtext-sm ${
                          (formik.errors.confirmPassword || !validations) &&
                          "p-invalid"
                        }`}
                      />
                      <i
                        className={
                          showConfirmPassword ? "pi pi-eye-slash" : "pi pi-eye"
                        }
                        onClick={_ =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      />
                    </span>
                    {formik.errors.confirmPassword && (
                      <span className="p-error text-xs">
                        {formik.errors.confirmPassword}
                      </span>
                    )}
                  </div>

                  <div className="col-span-12 text-center -mt-3">
                    {!validations && (
                      <span className="p-error text-xs">
                        As senhas não são iguais.
                      </span>
                    )}
                  </div>

                  <div className="col-span-12 flex flex-col justify-end mt-7 lg:mt-0">
                    <small className="text-gray-50 mb-1">Requisitos:</small>
                    <div className="flex">
                      <Tag
                        value="8 caracteres"
                        className={`border bg-transparent ${verifyPasswordMinimumLength()}`}
                      />
                      <Tag
                        value="Uma letra maiúscula"
                        className={`border bg-transparent ml-3 ${verifyPasswordCaracterUpper()}`}
                      />
                    </div>
                  </div>

                  <div className="col-span-6 lg:col-span-8 flex">
                    <Link
                      to={`/recuperar/senha/${user}`}
                      className="flex items-center"
                    >
                      <BsArrowLeft className="text-blue-100" />
                      <small className="text-gray-50 ml-1">Voltar</small>
                    </Link>
                  </div>

                  <div className="col-span-6 lg:col-span-4 p-fluid flex items-end">
                    <Button
                      label="Alterar"
                      type="submit"
                      disabled={
                        formik.isSubmitting ||
                        !formik.values.token ||
                        !formik.values.email ||
                        formik.values.password.length < 8 ||
                        formik.values.confirmPassword.length < 8 ||
                        !validatePasswordCharacterUpper(
                          formik.values.password
                        ) ||
                        !validatePasswordCharacterUpper(
                          formik.values.confirmPassword
                        )
                      }
                    />
                  </div>
                </div>
              </form>
            )}
          </Card>

          <p className="mt-5 text-gray-50 text-center text-sm">
            Ainda não é cadastrado?
            <Link to="/registro" className="text-blue-100 underline ml-1">
              Cadastre-se agora!
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
