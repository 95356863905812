import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useHistory, useParams } from "react-router-dom";

import { useAuth, UserSession } from "../contexts/UserContext";
import { UserType } from "../store/types/UserType";
import { UserServices } from "../services/userServices";

import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import { BsArrowLeft } from "react-icons/bs";

import logo from "../assets/img/logo.png";

type User = {
  email: string;
  password: string;
};

type ParamsRouter = {
  user: string;
};

const initialValues: User = {
  email: "",
  password: "",
};

interface ReponseLoginProps {
  status: number;
  data: {
    token: string;
  };
}

interface ReponseUserProps {
  status: number;
  data: UserSession;
}

export const validationSchema = Yup.object().shape({
  email: Yup.string().email("Email inválido").required("Campo obrigatório"),
  password: Yup.string().required("Campo obrigatório"),
});

export default function LoginDoctor() {
  const { login, setSessionUser } = useAuth();
  const history = useHistory();
  const { user } = useParams<ParamsRouter>();
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState<string | null>(null);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values: User) => {
      try {
        const { data }: ReponseLoginProps = await UserServices.login(
          values
        );

        login(data.token as string);

        await getUserData();
      } catch(error) {
        setLoginError("Email ou senha incorretos");
      }
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const getUserData = async () => {
    const { status, data }: ReponseUserProps = await UserServices.home();

    setSessionUser(data);

    if (status === 200) {
      if (data.type === UserType.DOCTOR) {
        history.push("/medico/pacientes");
      } else {
        history.push("/paciente");
      }
    }
  };

  const headerCard = (
    <div className="bg-blue-100 text-white py-3 px-7 rounded-t-md">
      <h1 className="font-exo-light">ÁREA DE LOGIN</h1>
      <h1 className="font-exo">
        {user === "medico" ? "MÉDICO(A)" : "PACIENTE"}
      </h1>
    </div>
  );

  return (
    <div className="w-screen h-screen flex flex-col items-center bg-blue-10 p-5">
      <img src={logo} alt="Examine" className="w-64 mt-20 2xl:mt-40" />

      <div className="w-full grid grid-cols-12 2xl:grid-cols-11 mt-10">
        <div className="col-span-12 lg:col-start-5 lg:col-span-4 2xl:col-start-5 2xl:col-span-3">
          <Card header={headerCard} className="shadow rounded-md">
            <form onSubmit={formik.handleSubmit}>
              <div className="grid grid-cols-12 px-5">
                <div className="col-span-12 p-fluid p-field">
                  <span className="p-input-icon-left">
                    <i className="pi pi-user" />
                    <InputText
                      name="email"
                      type="text"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      placeholder="Email"
                      className={`p-inputtext-sm ${
                        (formik.errors.email || loginError) && "p-invalid"
                      }`}
                    />
                  </span>
                  {formik.errors.email && (
                    <span className="p-error text-xs">
                      {formik.errors.email}
                    </span>
                  )}
                </div>

                <div className="col-span-12 p-fluid p-field">
                  <span className="p-input-icon-left p-input-icon-right">
                    <i className="pi pi-lock" />
                    <InputText
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      placeholder="Senha"
                      className={`p-inputtext-sm ${
                        (formik.errors.password || loginError) && "p-invalid"
                      }`}
                    />
                    <i
                      className={showPassword ? "pi pi-eye-slash" : "pi pi-eye"}
                      onClick={_ => setShowPassword(!showPassword)}
                    />
                  </span>
                  {formik.errors.password && (
                    <span className="p-error text-xs">
                      {formik.errors.password}
                    </span>
                  )}

                  {loginError && (
                    <span className="p-error text-xs">{loginError}</span>
                  )}
                </div>

                <div className="col-span-12 p-field">
                  <Link
                    to={`/recuperar/senha/${user}`}
                    className="text-xs text-gray-80 underline float-right"
                  >
                    Esqueceu a senha?
                  </Link>
                </div>

                <div className="col-span-7 flex">
                  <Link to="/" className="flex items-center">
                    <BsArrowLeft className="text-blue-100" />
                    <small className="text-gray-50 ml-1">Voltar</small>
                  </Link>
                </div>

                <div className="col-span-5 p-fluid">
                  <Button
                    label="Entrar"
                    type="submit"
                    disabled={formik.isSubmitting}
                  />
                </div>
              </div>
            </form>
          </Card>

          <p className="mt-5 text-gray-50 text-center text-sm">
            Ainda não é cadastrado?
            <Link to="/registro" className="text-blue-100 underline ml-1">
              Cadastre-se agora!
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
