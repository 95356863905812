import { Card } from "primereact/card";
import { Link, useRouteMatch } from "react-router-dom";
// import { Avatar } from "primereact/avatar";

import {
  AiOutlineMan,
  AiOutlineWoman,
} from "react-icons/ai";

// import avatar from "../../../assets/img/avatar.png";
import woman from "../../../assets/img/woman.svg";
import man from "../../../assets/img/man.svg";
import { useEffect, useState } from "react";

type Patient = {
  name: string;
  genre: string;
  age: number;
  birthDate: string;
  bloodType: string;
  height: string;
  weight: string;
  imc: string;
  scheduleData: string;
  realizationDate?: string;
};

const initialPatient: Patient[] = [
  {
    name: "Sarah Dias Pinheiro",
    genre: "female",
    age: 25,
    birthDate: "10/01/1995",
    bloodType: "A+",
    height: "1,77m",
    weight: "66kg",
    imc: "18,3",
    scheduleData: "20/05/2020",
    realizationDate: "20/05/2020",
  },
  {
    name: "Bruno Neto",
    genre: "male",
    age: 25,
    birthDate: "10/01/1995",
    bloodType: "A+",
    height: "1,77m",
    weight: "66kg",
    imc: "18,3",
    scheduleData: "20/05/2020",
    realizationDate: "20/05/2020",
  },
  {
    name: "Ana Maria Albuquerque",
    genre: "female",
    age: 25,
    birthDate: "10/01/1995",
    bloodType: "A+",
    height: "1,77m",
    weight: "66kg",
    imc: "18,3",
    scheduleData: "20/05/2020",
    realizationDate: "20/05/2020",
  },
];

const patients = [
  {
    "id": 1,
    "gender":"male",
    "birthDate":"04/06/1987",
    "age": "34 anos",
    "name": "Rodrigo Haus da Silva Bacellar",
    "exams": [
      {
        "title":"Hemograma",
        "date":"17/11/2021",
        "url":"https://pgw-teste-manual.s3.amazonaws.com/exams/haus3.pdf"
      },
      {
        "title":"Hemograma",
        "date":"08/04/2019",
        "url":"https://pgw-teste-manual.s3.amazonaws.com/exams/haus2.pdf"
      },
      {
        "title":"Hemograma",
        "date":"29/11/2018",
        "url":"https://pgw-teste-manual.s3.amazonaws.com/exams/haus1.pdf"
      },
    ],
  },
  {
    "id": 2,
    "name": "Heribaldo do Nascimento Lyra",
    "gender":"male",
    "birthDate":"28/02/1938",
    "age": "83 anos",
    "exams": [
      {
        "title":"Hemograma",
        "date":"12/11/2021",
        "url":"https://pgw-teste-manual.s3.amazonaws.com/exams/lyra3.pdf"
      },
      {
        "title":"Hemograma",
        "date":"27/08/2021",
        "url":"https://pgw-teste-manual.s3.amazonaws.com/exams/lyra2.pdf"
      },
      {
        "title":"Hemograma",
        "date":"09/04/2021",
        "url":"https://pgw-teste-manual.s3.amazonaws.com/exams/lyra1.pdf"
      },
    ],
  },
  {
    "id": 3,
    "name": "Alda Aparecida Haus da Silva Bacellar",
    "gender":"female",
    "birthDate":"02/07/1960",
    "age": "61 anos",
    "exams": [
      {
        "title":"Hemograma",
        "date":"",
        "url":"https://pgw-teste-manual.s3.amazonaws.com/exams/alda3.pdf"
      },
      {
        "title":"Hemograma",
        "date":"",
        "url":"https://pgw-teste-manual.s3.amazonaws.com/exams/alda2.pdf"
      },
      {
        "title":"Hemograma",
        "date":"",
        "url":"https://pgw-teste-manual.s3.amazonaws.com/exams/alda1.pdf"
      },
    ],
  }
];

export default function DoctorPatientsShow() {
  const [patient, setPatient] = useState(patients[0]);
  const { url } = useRouteMatch();

  useEffect(() => {
    const pathFragments = url.split('/').reverse();
    console.log(pathFragments);
    setPatient(patients[+pathFragments[0] - 1]);
  }, []);

  return (
    <div className="col-span-10 col-start-2">
      <div className="grid grid-cols-12 gap-3">

        <div className="col-span-12 lg:col-span-12">
          <div className="w-full">
            <Link to="/medico/pacientes" className="text-blue-100">Voltar para lista de pacientes</Link>
            <Card className="mt-3 shadow-none px-2 sticky">
              <div className="flex flex-col">
                <div className="p-grid">
                  <div className="p-col-12 p-md-4">
                    <div className="flex items-center mb-6">
                      {/* <Avatar image={avatar} size="xlarge" shape="circle" /> */}
                      <div>
                        <h4 className="text-gray-80 font-bold">
                          {patient.name}
                        </h4>
                        <span className="text-gray-50 text-sm flex items-center">
                          {patient.gender === "female" ? (
                            <AiOutlineWoman className="text-xl mr-1" />
                          ) : (
                            <AiOutlineMan className="text-xl mr-1" />
                          )}
                          {patient.age} anos ({patient.birthDate})
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <div className="mr-12">
                        <p className="text-sm text-gray-50 text-center mb-5">
                          <span className="block mb-1">PESO</span>
                          <strong className="text-gray-80 text-lg">
                            {initialPatient[0].weight}
                          </strong>
                        </p>
                        <p className="text-sm text-gray-50 text-center">
                          <span className="block mb-1">IMC</span>
                          <strong className="text-gray-80 text-lg">
                            {initialPatient[0].imc}
                          </strong>
                        </p>
                      </div>
                      {patient.gender === "female" ? (
                        <img src={woman} alt="Feminino" width="60" />
                      ) : (
                        <img src={man} alt="Masculino" width="60" />
                      )}
                      <div className="ml-12">
                        <p className="text-sm text-gray-50 text-center mb-5">
                          <span className="block mb-1">SANGUE</span>

                          <strong className="text-gray-80 text-lg">
                            {initialPatient[0].bloodType}
                          </strong>
                        </p>
                        <p className="text-sm text-gray-50 text-center">
                          <span className="block mb-1">ALTURA</span>
                          <strong className="text-gray-80 text-lg">
                            {initialPatient[0].height}
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="p-col-12 p-md-8">
                    <h4 className="text-gray-80 font-bold mt-2">Exames</h4>
                    <table className="border-collapse table-auto w-full text-sm my-8">
                      <thead>
                        <tr>
                          <th className="border-b dark:border-slate-600 font-medium p-3 pl-2 pt-0 text-slate-400 dark:text-slate-200 text-left">Nome</th>
                          <th className="border-b dark:border-slate-600 font-medium p-3 pl-2 pt-0 text-slate-400 dark:text-slate-200 text-left">Data</th>
                          <th className="border-b dark:border-slate-600 font-medium p-3 pl-2 pt-0 text-slate-400 dark:text-slate-200 text-left"></th>
                        </tr>
                      </thead>
                      <tbody className="bg-white dark:bg-slate-800">
                        {patient.exams.map((exam) => (
                          <tr key={exam.url}>
                            <td className="border-b border-slate-100 dark:border-slate-700 p-3 pl-2 text-slate-500 dark:text-slate-400">{exam.title}</td>
                            <td className="border-b border-slate-100 dark:border-slate-700 p-3 pl-2 text-slate-500 dark:text-slate-400">{exam.date}</td>
                            <td className="border-b border-slate-100 dark:border-slate-700 p-3 pl-2 text-slate-500 dark:text-slate-400">
                              <a href={exam.url} target="_blank" rel="noreferrer" className="text-blue-100">Visualizar</a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
