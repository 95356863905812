import { Card } from "primereact/card";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Carousel } from "primereact/carousel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import {
  AiOutlineMan,
  AiOutlineWoman,
  AiOutlineCalendar,
} from "react-icons/ai";
import { FiDownload } from "react-icons/fi";

import avatar from "../assets/img/avatar.png";
import woman from "../assets/img/woman.svg";

type Patient = {
  name: string;
  genre: string;
  age: number;
  birthDate: string;
  bloodType: string;
  height: string;
  weight: string;
  imc: string;
  scheduleData: string;
  realizationDate?: string;
  exams: Exam[];
};

type Exam = {
  name: string;
  realizationDate: string;
  type: string;
  method: string;
  material: string;
  results: ExamResults[];
};

type ExamResults = {
  name: string;
  result: string;
  reference: string;
};

const initialPatient: Patient[] = [
  {
    name: "Sarah Dias Pinheiro",
    genre: "female",
    age: 25,
    birthDate: "10/01/1995",
    bloodType: "A+",
    height: "1,77m",
    weight: "66kg",
    imc: "18,3",
    scheduleData: "20/05/2020",
    realizationDate: "20/05/2020",
    exams: [
      {
        name: "Hemograma",
        realizationDate: "28/05/2020 - 16:55",
        type: "Eritograma",
        method: " Análise realizada por Citometria de fluxo fluorescente.",
        material: "Sangue total com EDTA",
        results: [
          {
            name: "Hemacias",
            result: "4,16 milhões/mm3",
            reference: "4,00 a 5,40 milhões/mm3",
          },
          {
            name: "Hemoglobina",
            result: "10,2 g/dL",
            reference: "12,0 a 15,8 g/dL",
          },
          { name: "Hematócrito", result: "31,1 %", reference: "33,0 a 47,8 %" },
          { name: "VCM", result: "74,6 fl", reference: "80,0 a 98,0 fl" },
          { name: "HCM", result: "24,5 pg", reference: "26,8 a 32,9 pg" },
          { name: "CHCM", result: "32,8 g/DL", reference: "30,0 a 36,5 g/Dl" },
          { name: "RDW", result: "13,8", reference: "11,0 a 16,0" },
        ],
      },
      {
        name: "Hemograma",
        realizationDate: "28/05/2020 - 16:55",
        type: "Leucograma",
        method: " Análise realizada por Citometria de fluxo fluorescente.",
        material: "Sangue total com EDTA",
        results: [],
      },
      {
        name: "Hemograma",
        realizationDate: "28/05/2020 - 16:55",
        type: "Leucograma",
        method: " Análise realizada por Citometria de fluxo fluorescente.",
        material: "Sangue total com EDTA",
        results: [],
      },
      {
        name: "Hemograma",
        realizationDate: "28/05/2020 - 16:55",
        type: "Leucograma",
        method: " Análise realizada por Citometria de fluxo fluorescente.",
        material: "Sangue total com EDTA",
        results: [],
      },
    ],
  },
  {
    name: "Bruno Neto",
    genre: "male",
    age: 25,
    birthDate: "10/01/1995",
    bloodType: "A+",
    height: "1,77m",
    weight: "66kg",
    imc: "18,3",
    scheduleData: "20/05/2020",
    realizationDate: "20/05/2020",
    exams: [],
  },
  {
    name: "Ana Maria Albuquerque",
    genre: "female",
    age: 25,
    birthDate: "10/01/1995",
    bloodType: "A+",
    height: "1,77m",
    weight: "66kg",
    imc: "18,3",
    scheduleData: "20/05/2020",
    realizationDate: "20/05/2020",
    exams: [],
  },
];

export const CardNextExams = () => {
  return (
    <div className="w-full mb-5">
      <h2 className="font-bold text-sm text-gray-80 mb-2">PRÓXIMOS EXAMES</h2>
      <hr />
      <Carousel
        value={initialPatient}
        numVisible={1}
        numScroll={1}
        itemTemplate={(item: Patient) => (
          <Card className="mt-3 mb-7 shadow-none px-2">
            <div className="flex flex-col items-center">
              <Avatar image={avatar} size="xlarge" shape="circle" />
              <h4 className="font-exo text-gray-80 mt-3">{item.name}</h4>
              <span className="text-gray-50 text-sm mt-1 flex items-center">
                {item.genre === "female" ? (
                  <AiOutlineWoman className="text-xl mr-1" />
                ) : (
                  <AiOutlineMan className="text-xl mr-1" />
                )}
                {item.age} anos ({item.birthDate})
              </span>
              <span className="text-gray-50 text-sm mt-1 flex items-center">
                <AiOutlineCalendar className="text-xl mr-2" />
                Agendado em: {item.scheduleData}
              </span>
              <Button label="Realizar exame" className="w-full button-card" />
            </div>
          </Card>
        )}
      />
    </div>
  );
};

export const CardLastPatientsAttended = () => {
  return (
    <div className="w-full">
      <h2 className="font-bold text-sm text-gray-80 mb-2">
        ÚLTIMOS PACIENTES ATENDIDOS
      </h2>
      <hr />
      <Carousel
        value={initialPatient}
        numVisible={1}
        numScroll={1}
        itemTemplate={(item: Patient) => (
          <Card className="mt-3 mb-7 shadow-none px-2 sticky">
            <div className="flex flex-col items-center">
              <Avatar image={avatar} size="xlarge" shape="circle" />
              <h4 className="font-exo text-gray-80 mt-3">{item.name}</h4>
              <span className="text-gray-50 text-sm mt-1 flex items-center">
                <AiOutlineMan className="text-xl mr-1" />
                {item.age} anos ({item.birthDate})
              </span>
              <span className="text-gray-50 text-sm mt-1 flex items-center">
                <AiOutlineCalendar className="text-xl mr-2" />
                Realizado em: {item.realizationDate}
              </span>
              <Divider />

              <div className="flex justify-between w-full text-gray-50 text-xs">
                <div className="flex flex-col items-center">
                  SANGUE
                  <strong className="text-gray-80">{item.bloodType}</strong>
                </div>
                <div className="flex flex-col items-center">
                  ALTURA
                  <strong className="text-gray-80">{item.height}</strong>
                </div>
                <div className="flex flex-col items-center">
                  PESO
                  <strong className="text-gray-80">{item.weight}</strong>
                </div>
                <div className="flex flex-col items-center">
                  IMC
                  <strong className="text-gray-80">{item.imc}</strong>
                </div>
              </div>
              <FiDownload className="absolute top-4 right-3 text-xl text-blue-100 cursor-pointer" />
              <Button label="Visualizar exame" className="w-full button-card" />
            </div>
          </Card>
        )}
      />
    </div>
  );
};

export const CardRecentExams = () => {
  return (
    <div className="w-full">
      <h2 className="font-bold text-sm text-gray-80 mb-2">EXAMES RECENTES</h2>
      <hr />
      {initialPatient[0].exams.map(exam => (
        <Card className="mt-3 mb-12 shadow-none px-2 sticky">
          <div className="flex flex-col">
            <h4 className="text-gray-80 font-bold">{exam.name}</h4>
            <span className="text-gray-50 text-sm mt-2">
              {exam.realizationDate}
            </span>
            <small className="text-gray-80 font-bold mt-2">{exam.type}</small>
            <FiDownload className="absolute top-6 right-5 text-xl text-blue-100 cursor-pointer" />
            <Button label="Visualizar exame" className="button-card" />
          </div>
        </Card>
      ))}
    </div>
  );
};

export const CardLastExamResult = () => {
  return (
    <div className="w-full">
      <h2 className="font-bold text-sm text-gray-80 mb-2">
        RESULTADO DO ÚLTIMO EXAME
      </h2>
      <hr />
      <Card className="mt-3 shadow-none px-2 sticky">
        <div className="flex flex-col">
          <div className="flex items-center mb-2">
            <h4 className="text-gray-80 font-bold">
              {initialPatient[0].exams[0].name}
            </h4>
            <span className="text-gray-50 text-sm ml-5">
              {initialPatient[0].exams[0].realizationDate}
            </span>
          </div>
          <hr />

          <div className="p-grid p-mt-3 p-mb-3">
            <div className="p-col-12 p-md-6">
              <div className="flex items-center mb-6">
                <Avatar image={avatar} size="xlarge" shape="circle" />
                <div className="ml-3">
                  <h4 className="text-gray-80 font-bold ml-1">
                    {initialPatient[0].name}
                  </h4>
                  <span className="text-gray-50 text-sm flex items-center">
                    {initialPatient[0].genre === "female" ? (
                      <AiOutlineWoman className="text-xl mr-1" />
                    ) : (
                      <AiOutlineMan className="text-xl mr-1" />
                    )}
                    {initialPatient[0].age} anos ({initialPatient[0].birthDate})
                  </span>
                </div>
              </div>

              <p className="text-gray-80 text-sm mt-2">
                <strong>Método:</strong> {initialPatient[0].exams[0].method}
              </p>
              <p className="text-gray-80 text-sm mt-2">
                <strong>Material:</strong> {initialPatient[0].exams[0].material}
              </p>
            </div>
            <div className="p-col-12 p-md-6 flex justify-around items-center">
              <div>
                <p className="text-sm text-gray-50 text-center mb-5">
                  <span className="block mb-1">PESO</span>
                  <strong className="text-gray-80 text-lg">
                    {initialPatient[0].weight}
                  </strong>
                </p>
                <p className="text-sm text-gray-50 text-center">
                  <span className="block mb-1">IMC</span>
                  <strong className="text-gray-80 text-lg">
                    {initialPatient[0].imc}
                  </strong>
                </p>
              </div>
              <img src={woman} alt="Feminino" width="60" />
              <div>
                <p className="text-sm text-gray-50 text-center mb-5">
                  <span className="block mb-1">SANGUE</span>

                  <strong className="text-gray-80 text-lg">
                    {initialPatient[0].bloodType}
                  </strong>
                </p>
                <p className="text-sm text-gray-50 text-center">
                  <span className="block mb-1">ALTURA</span>
                  <strong className="text-gray-80 text-lg">
                    {initialPatient[0].height}
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <hr />

          <h4 className="text-gray-80 font-bold mt-8">
            {initialPatient[0].exams[0].type}
          </h4>

          <DataTable
            value={initialPatient[0].exams[0].results}
            className="md:mx-5"
          >
            <Column
              field="name"
              bodyClassName="text-xs md:text-sm text-gray-80"
              bodyStyle={{ textAlign: "right" }}
              headerClassName="md:w-3/12"
            ></Column>
            <Column
              field="result"
              header="Resultados"
              bodyClassName="text-xs md:text-sm font-bold"
              headerClassName="text-xs md:text-sm"
              headerStyle={{
                textAlign: "center",
                fontWeight: "bold",
              }}
            ></Column>
            <Column
              field="reference"
              header="Referencial (Mulheres)"
              bodyClassName="text-xs md:text-sm text-gray-50"
              headerClassName="text-xs md:text-sm text-gray-50"
              headerStyle={{
                textAlign: "center",
                fontWeight: "lighter",
                color: "rgba(0,0,0,0.5)",
              }}
            ></Column>
          </DataTable>

          <FiDownload className="absolute top-6 right-5 text-xl text-blue-100 cursor-pointer" />
          <div className="w-full md:w-5/12 mx-auto">
            <Button label="Visualizar exame" className="button-card w-full" />
          </div>
        </div>
      </Card>
    </div>
  );
};
