import { useAuth } from "../../contexts/UserContext";

import { CardNextExams, CardLastPatientsAttended } from "../../components/Card";
import { GraphicArea, GraphicDoughnut } from "../../components/Graphic";

export default function Doctor() {
  const { user } = useAuth();

  return (
    <div className="col-span-10 col-start-2">
      <div className="grid grid-cols-12 gap-x-3">
        <div className="col-span-12 text-gray-80 my-8">
          {/* <h2 className="font-exo text-xl ">Bom dia, Dr(a) {user.firstName}</h2> */}
          <p className="text-sm">
            Aqui está o seu resumo de atividades até o dia <strong>{(new Date()).toLocaleDateString('pt-BR')}</strong>
          </p>
        </div>

        <div className="col-span-12 lg:col-span-3 mb-5 lg:mb-0">
          <CardNextExams />
          <CardLastPatientsAttended />
        </div>
        <div className="col-span-12 lg:col-start-6 lg:col-span-7">
          <GraphicArea />
          <GraphicDoughnut />
        </div>
      </div>
    </div>
  );
}
