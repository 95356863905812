// import axios from 'axios';
// import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

const patients = [
  {
    "id": 1,
    "name": "Rodrigo Haus da Silva Bacellar",
  },
  {
    "id": 2,
    "name": "Heribaldo do Nascimento Lyra",
  },
  {
    "id": 3,
    "name": "Alda Aparecida Haus da Silva Bacellar",
  },
];

export default function DoctorPatientsList() {
  // const [patients, setPatients] = useState([]);

  // useEffect(() => {
  //   async function getPatients() {
  //     const response = await axios.get('https://pgw-teste-manual.s3.amazonaws.com/patients.json');
  //     console.log(response);
  //   }

  //   getPatients();
  // }, []);

  return (
    <div className="col-span-10 col-start-2">
      <div className="grid grid-cols-12 gap-x-3">
        <div className="col-span-12 text-gray-80 my-8">
          <h2 className="font-exo text-xl ">Lista de pacientes</h2>
          <table className="border-collapse table-auto w-full text-sm my-8">
            <thead>
              <tr>
                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">Nome</th>
                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left"></th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-slate-800">
              {patients.map((patient) => (
                <tr key={patient.id}>
                  <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">{patient.name}</td>
                  <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                    <Link to={`/medico/pacientes/${patient.id}`} className="text-blue-100">Detalhes do paciente</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
