import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

import { Button } from "primereact/button";
import { Divider } from "primereact/divider";

import logo from "../assets/img/logo.png";

export default function Home() {
  return (
    <div className="w-screen h-screen flex flex-col items-center bg-blue-10 text-gray-80 text-center p-5">
      <img src={logo} alt="Examine" className="w-64 mt-20 2xl:mt-40" />

      <h2 className="mt-16 lg:mt-20 text-xl font-exo ">
        Olá! Seja bem-vindo(a)
      </h2>
      <p>Para acessar nossa plataforma, faça login ou cadastre-se.</p>

      <div className="w-full grid grid-cols-11 mt-5 lg:mt-10">
        <div className="col-start-2 col-span-9 lg:col-start-4 lg:col-span-2 p-fluid py-4">
          <Link to="/entrar/medico">
            <Button label="Entrar como médico(a)" />
          </Link>

          <Link to="/entrar/paciente">
            <Button label="Entrar como paciente" className="p-mt-2" />
          </Link>
        </div>

        <div className="col-start-3 col-span-7 lg:col-span-1">
          <Divider align="center" layout={isMobile ? "horizontal" : "vertical"}>
            ou
          </Divider>
        </div>

        <div className="col-start-2 col-span-9 lg:col-span-2 p-fluid py-4 text-center flex flex-col justify-center">
          <Link to="/registro">
            <Button
              label="Quero me cadastrar"
              className="p-button-secondary p-button-outlined"
            />
          </Link>

          <small className="text-gray-50 mt-2 font-medium">
            Leva só 2 minutinhos
          </small>
        </div>
      </div>
    </div>
  );
}
