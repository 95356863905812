import { useState } from "react";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";

import { UserType, User } from "../store/types/UserType";
import { UserServices } from "../services/userServices";

import {
  validateEmail,
  validateCPF,
  validatePasswordCharacterUpper,
} from "../utils/validators";

import { Card } from "primereact/card";
import { ProgressBar } from "primereact/progressbar";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import { BsArrowLeft } from "react-icons/bs";

import logo from "../assets/img/logo.png";
import doctorIcon from "../assets/img/doctor.svg";
import patientIcon from "../assets/img/patient.svg";
import success from "../assets/img/success.svg";

interface RegisterUser extends User {
  confirmPassword: string;
}

const initialValues: RegisterUser = {
  type: null,
  firstName: "",
  lastName: "",
  email: "",
  cpf: "",
  phone: "",
  password: "",
  confirmPassword: "",
};

export default function Register() {
  const history = useHistory();
  const [progress, setProgress] = useState<number>(20);
  const [validations, setValidations] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values: RegisterUser) => {
      if (values.password !== values.confirmPassword) {
        setValidations(false);
      } else {
        const { status } = await UserServices.signUp({
          type: values.type,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          cpf: values.cpf.replace(/\D/g, ""),
          phone: values.phone.replace(/\D/g, ""),
          password: values.password,
        });

        if (status === 201) {
          setProgress(0);
        }
      }
    },
  });

  const handleDisabledButton = () => {
    let disabled = true;
    switch (progress) {
      case 20:
        disabled =
          !formik.values.firstName.trim() || !formik.values.lastName.trim();
        break;
      case 40:
        disabled = !formik.values.email;
        break;
      case 60:
        disabled = !formik.values.cpf;
        break;
      case 80:
        disabled = !formik.values.phone;
        break;
      default:
        disabled =
          formik.values.password.length < 8 ||
          formik.values.confirmPassword.length < 8 ||
          !validatePasswordCharacterUpper(formik.values.password) ||
          !validatePasswordCharacterUpper(formik.values.confirmPassword);
        break;
    }
    return disabled;
  };

  const addProgress = () => {
    switch (progress) {
      case 40:
        setValidations(validateEmail(formik.values.email));
        validateEmail(formik.values.email) && setProgress(progress + 20);
        break;
      case 60:
        setValidations(validateCPF(formik.values.cpf));
        validateCPF(formik.values.cpf) && setProgress(progress + 20);
        break;
      default:
        progress < 100 && setProgress(progress + 20);
        break;
    }
  };

  const verifyPasswordMinimumLength = () => {
    const { password, confirmPassword } = formik.values;

    if (!password && !confirmPassword) {
      return "text-gray-20 border-gray-20";
    } else if (password.length < 8 || confirmPassword.length < 8) {
      return "text-red-600 border-red-600";
    } else {
      return "text-blue-100 border-blue-100";
    }
  };

  const verifyPasswordCaracterUpper = () => {
    const { password, confirmPassword } = formik.values;

    if (!password && !confirmPassword) {
      return "text-gray-20 border-gray-20";
    } else if (
      validatePasswordCharacterUpper(password) &&
      validatePasswordCharacterUpper(confirmPassword)
    ) {
      return "text-blue-100 border-blue-100";
    } else {
      return "text-red-600 border-red-600";
    }
  };

  const headerCard = (
    <div className="bg-blue-100 text-white py-3 px-7 rounded-t-md">
      <h1 className="font-exo-light">ÁREA DE</h1>
      <h1 className="font-exo">CADASTRO</h1>
    </div>
  );

  return (
    <div className="w-screen h-screen flex flex-col items-center bg-blue-10 p-5">
      <img src={logo} alt="Examine" className="w-64 mt-20 2xl:mt-40" />

      <div className="w-full grid grid-cols-12 mt-10">
        <div className="col-span-12 lg:col-start-4 lg:col-span-6">
          <Card header={headerCard} className="shadow rounded-md">
            {formik.values.type === null ? (
              <>
                <div className="col-span-12 mb-4">
                  <h2 className="text-center font-exo text-medium md:text-xl text-gray-80 mb-7">
                    Você quer se cadastrar como:
                  </h2>
                  <div className="p-grid p-justify-center mb-7">
                    <button
                      className="button-custom bg-blue-10 flex flex-col items-center p-4 rounded-2xl mr-3 md:mr-5"
                      onClick={() =>
                        formik.setFieldValue("type", UserType.DOCTOR)
                      }
                    >
                      <img src={doctorIcon} alt="Médico Ícone" width="80" />
                      <span className="text-gray-80 mt-2 text-sm font-medium">
                        Médico(a)
                      </span>
                    </button>

                    <button
                      className="button-custom bg-blue-10 flex flex-col items-center p-4 rounded-2xl ml-3 md:ml-5"
                      onClick={() =>
                        formik.setFieldValue("type", UserType.PATIENT)
                      }
                    >
                      <img src={patientIcon} alt="Paciente Ícone" width="80" />
                      <span className="text-gray-80 mt-2 text-sm font-medium">
                        Paciente
                      </span>
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div className="grid grid-cols-12 gap-x-4">
                {progress !== 0 && (
                  <div className="col-start-3 col-span-8 mb-10">
                    <ProgressBar value={progress} style={{ height: "8px" }} />
                  </div>
                )}

                {progress === 20 && (
                  <>
                    <div className="col-span-12 mb-4">
                      <h2 className="text-center font-exo text-medium md:text-xl text-gray-80">
                        Qual é o seu nome completo?{" "}
                        <span className="p-error">*</span>
                      </h2>
                    </div>
                    <div className="col-start-2 col-span-10 lg:col-start-3 lg:col-span-4 p-fluid">
                      <InputText
                        name="firstName"
                        type="text"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        placeholder="Nome"
                        className="p-inputtext-sm"
                      />
                    </div>
                    <div className="col-start-2 col-span-10 lg:col-span-4 p-fluid mt-3 lg:mt-0">
                      <InputText
                        name="lastName"
                        type="text"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        placeholder="Sobrenome"
                        className="p-inputtext-sm"
                      />
                    </div>
                  </>
                )}

                {progress === 40 && (
                  <>
                    <div className="col-span-12 mb-4">
                      <h2 className="text-center font-exo text-medium md:text-xl text-gray-80">
                        Qual é o seu email? <span className="p-error">*</span>
                      </h2>
                    </div>
                    <div className="col-start-2 col-span-10 lg:col-start-3 lg:col-span-8 p-fluid">
                      <InputText
                        name="email"
                        type="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        placeholder="Digite aqui"
                        className={
                          validations
                            ? "p-inputtext-sm"
                            : "p-inputtext-sm p-invalid"
                        }
                      />
                    </div>

                    <div className="col-span-12 text-center">
                      {!validations && (
                        <span className="p-error text-xs">
                          O email parece estar errado.
                        </span>
                      )}
                    </div>
                  </>
                )}

                {progress === 60 && (
                  <>
                    <div className="col-span-12 mb-4">
                      <h2 className="text-center font-exo text-medium md:text-xl text-gray-80">
                        Qual é o seu CPF? <span className="p-error">*</span>
                      </h2>
                    </div>

                    <div className="col-start-2 col-span-10 lg:col-start-4 lg:col-span-6 p-fluid">
                      <InputMask
                        name="cpf"
                        type="text"
                        mask="999.999.999-99"
                        value={formik.values.cpf}
                        onChange={formik.handleChange}
                        placeholder="Digite aqui"
                        className={
                          validations
                            ? "p-inputtext-sm"
                            : "p-inputtext-sm p-invalid"
                        }
                      />
                    </div>

                    <div className="col-span-12 text-center">
                      {!validations && (
                        <span className="p-error text-xs">
                          Informe um número de cpf válido.
                        </span>
                      )}
                    </div>
                  </>
                )}

                {progress === 80 && (
                  <>
                    <div className="col-span-12 mb-4">
                      <h2 className="text-center font-exo text-medium md:text-xl text-gray-80">
                        Qual é o seu telefone?{" "}
                        <span className="p-error">*</span>
                      </h2>
                    </div>

                    <div className="col-start-2 col-span-10 lg:col-start-4 lg:col-span-6 p-fluid">
                      <InputMask
                        name="phone"
                        placeholder="Digite aqui"
                        mask="(99) 9999-9999?9"
                        className="p-inputtext-sm"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </>
                )}

                {progress === 100 && (
                  <>
                    <div className="col-span-12 mb-4">
                      <h2 className="text-center font-exo text-medium md:text-xl text-gray-80">
                        Qual será sua senha? <span className="p-error">*</span>
                      </h2>
                    </div>

                    <div className="col-span-10 col-start-2 lg:col-start-3 lg:col-span-4 p-fluid">
                      <span className="p-input-icon-right">
                        <i
                          className={
                            showPassword ? "pi pi-eye-slash" : "pi pi-eye"
                          }
                          onClick={_ => setShowPassword(!showPassword)}
                        />
                        <InputText
                          name="password"
                          type={showPassword ? "text" : "password"}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          placeholder="Senha"
                          className={
                            validations
                              ? "p-inputtext-sm"
                              : "p-inputtext-sm p-invalid"
                          }
                        />
                      </span>
                    </div>

                    <div className="col-span-10 col-start-2 lg:col-span-4 p-fluid mt-3 lg:mt-0">
                      <span className="p-input-icon-right">
                        <i
                          className={
                            showConfirmPassword
                              ? "pi pi-eye-slash"
                              : "pi pi-eye"
                          }
                          onClick={_ =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        />
                        <InputText
                          name="confirmPassword"
                          type={showConfirmPassword ? "text" : "password"}
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          placeholder="Confirmar senha"
                          className={
                            validations
                              ? "p-inputtext-sm"
                              : "p-inputtext-sm p-invalid"
                          }
                        />
                      </span>
                    </div>

                    <div className="col-span-12 text-center">
                      {!validations && (
                        <span className="p-error text-xs">
                          As senhas não são iguais.
                        </span>
                      )}
                    </div>
                  </>
                )}

                {progress === 0 && (
                  <>
                    <div className="col-span-12">
                      <img
                        src={success}
                        alt="Cadastro Realizado"
                        width="70"
                        className="mx-auto mt-4"
                      />
                      <h2 className="text-center mt-2 mb-1 font-exo text-xl text-gray-80">
                        Pronto, tudo certo!
                      </h2>
                      <p className="text-center text-gray-80 text-sm">
                        Seu cadastro está concluído, agora você já pode <br />{" "}
                        acessar a plataforma. Vamos?
                      </p>
                    </div>
                    <div className="col-start-5 col-span-4 p-fluid mt-8">
                      <Button
                        label="Entendido"
                        onClick={_ =>
                          history.push(
                            formik.values.type === UserType.DOCTOR
                              ? "/entrar/medico"
                              : "/entrar/paciente"
                          )
                        }
                      />
                    </div>
                  </>
                )}

                {progress !== 0 && (
                  <>
                    <div
                      className={`flex items-center mt-7 ${
                        progress === 100
                          ? "col-span-4 col-start-2 lg:col-span-2 lg:col-start-2"
                          : "col-span-10 col-start-2 lg:col-span-7 lg:col-start-2"
                      }`}
                    >
                      <div
                        className="flex items-center cursor-pointer"
                        onClick={_ =>
                          progress === 20
                            ? formik.setFieldValue("type", null)
                            : setProgress(progress - 20)
                        }
                      >
                        <BsArrowLeft className="text-blue-100" />
                        <small className="text-gray-50 ml-1">
                          Voltar passo
                        </small>
                      </div>
                    </div>

                    {progress === 100 && (
                      <div className="col-span-6 lg:col-span-5 flex flex-col justify-end mt-7 lg:mt-0">
                        <small className="text-gray-50 mb-1">Requisitos:</small>
                        <div className="flex">
                          <Tag
                            value="8 caracteres"
                            className={`border bg-transparent ${verifyPasswordMinimumLength()}`}
                          />
                          <Tag
                            value="Uma letra maiúscula"
                            className={`border bg-transparent ml-3 ${verifyPasswordCaracterUpper()}`}
                          />
                        </div>
                      </div>
                    )}

                    <div className="col-span-10 col-start-2 lg:col-span-3 p-fluid flex flex-col mt-2 lg:mt-7">
                      <span className="p-error text-xs mb-0.5">
                        * Obrigatório
                      </span>
                      <Button
                        label={progress === 100 ? "Finalizar" : "Avançar"}
                        type="button"
                        onClick={
                          progress === 100 ? formik.submitForm : addProgress
                        }
                        disabled={handleDisabledButton()}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </Card>

          <p className="mt-5 text-gray-50 text-center text-sm">
            Já tem cadastro?
            <Link to="/" className="text-blue-100 underline ml-1">
              Faça o login!
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
