export enum UserType {
  PATIENT = "PATIENT",
  DOCTOR = "DOCTOR",
}
export interface User {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  type: UserType | null;
  cpf: string;
};
