import { Link, useRouteMatch } from "react-router-dom";

// import { Button } from "primereact/button";

import { FiHome, FiFile, FiUser } from "react-icons/fi";

import iconLogo from "../assets/img/96x96/96x96.png";

const menuPatient = [
  {
    label: "Início",
    active: true,
    icon: <FiHome />,
    to: "/paciente",
  },
  {
    label: "Seus exames",
    active: false,
    icon: <FiFile />,
    to: "/paciente/exames",
  },
];

const menuDoctor = [
  // {
  //   label: "Início",
  //   active: true,
  //   icon: <FiHome />,
  //   to: "/medico",
  // },
  // {
  //   label: "Exames",
  //   active: false,
  //   icon: <FiFile />,
  //   to: "/medico/exames",
  // },
  {
    label: "Pacientes",
    active: false,
    icon: <FiUser />,
    to: "/medico/pacientes",
  },
];

export default function Sidebar() {
  let { path } = useRouteMatch(); // TODO: Mudar para variável em contexto

  return (
    <div className="col-span-1 hidden md:block h-screen">
      <img src={iconLogo} alt="Examine" width="45" className="mx-auto my-6" />
      <div className="w-full flex flex-col justify-center mt-8 px-4">
        {/* <Button label="Novo exame" className="block" /> */}

        {path.indexOf("paciente") !== -1
          ? menuPatient.map(item => (
              <Link
                key={item.label}
                to={item.to}
                className={
                  path === item.to ? "mt-8 menu-item active" : "mt-8 menu-item"
                }
              >
                {item.icon}
                <span className="text-sm ml-2">{item.label}</span>
              </Link>
            ))
          : menuDoctor.map(item => (
              <Link
                key={item.label}
                to={item.to}
                className={
                  path === item.to ? "mt-8 menu-item active" : "mt-8 menu-item"
                }
              >
                {item.icon}
                <span className="text-sm ml-2">{item.label}</span>
              </Link>
            ))}
      </div>

      <span className="text-xs lg:text-sm text-gray-50 absolute bottom-3 left-5">
        Versão 1.0
      </span>
    </div>
  );
}
