type OptionsGraphic = {
  options: object;
};

export const area: OptionsGraphic = {
  options: {
    chart: {
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    colors: ["#30abb8"],
    markers: {
      size: 6,
      colors: ["#30abb8"],
      strokeColor: "#fff",
      strokeWidth: 3,
    },
    yaxis: {
      forceNiceScale: true,
      tickAmount: 4,
      min: 0,
      labels: {
        style: {
          colors: "rgba(0,0,0,0.5)",
          fontSize: "12px",
          fontFamily: "Roboto-Reg",
          fontWeight: 700,
        },
      },
    },
    xaxis: {
      type: "category",
      categories: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho"],
      labels: {
        style: {
          colors: "rgba(0,0,0,0.5)",
          fontSize: "12px",
          fontFamily: "Roboto-Reg",
          fontWeight: 700,
        },
      },
    },
  },
};

export const doughnut: OptionsGraphic = {
  options: {
    colors: ["#95d5db", "#30abb8"],
    labels: ["Masculino", "Feminino"],
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "70%",
          labels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
              offsetY: 5,
            },
            total: {
              show: true,
              showAlways: true,
              label: "",
              formatter: () => "GÊNERO",
            },
          },
        },
      },
    },
    legend: {
      fontSize: "15px",
      fontFamily: "Roboto-Reg",
      formatter: (seriesName: string, opts: any) => {
        return `${seriesName}: ${
          opts.w.globals.seriesPercent[opts.seriesIndex]
        }%`;
      },
      markers: {
        width: 8,
        height: 8,
      },
      itemMargin: {
        vertical: 5,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};
