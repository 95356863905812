import { Card } from "primereact/card";

import ReactApexChart from "react-apexcharts";

import { doughnut, area } from "./configGraphics";

export const GraphicArea = () => {
  return (
    <div className="w-full">
      <h2 className="font-bold text-sm text-gray-80 mb-2">
        NÚMERO DE EXAMES MENSAIS
      </h2>
      <hr />
      <Card className="mt-3 mb-4 shadow-none px-2">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center ">
          <div className="flex">
            <h1 className="font-exo text-4xl">2020</h1>
            <p className="ml-2 text-gray-50 text-sm">
              Janeiro - Junho <br />
              <i className="pi pi-angle-left md:mt-2 cursor-pointer opacity-30"></i>
              <i className="pi pi-angle-right ml-3 text-blue-100 cursor-pointer"></i>
            </p>
          </div>

          <p className="text-base text-gray-80 mt-5 lg:mt-0 text-center lg:text-right">
            <strong>230 exames</strong> nos últimos 6 meses.
          </p>
        </div>

        <ReactApexChart
          options={area.options}
          series={[
            {
              name: "Exames",
              data: [70, 110, 49, 93, 55, 130],
            },
          ]}
          type="area"
          height={200}
        />
      </Card>
    </div>
  );
};

export const GraphicDoughnut = () => {
  return (
    <div className="w-full mt-8">
      <h2 className="font-bold text-sm text-gray-80 mb-2">SEUS PACIENTES</h2>
      <hr />
      <Card className="mt-3 mb-4 shadow-none px-2">
        <h2 className="text-center text-gray-50">TOTAL DE PACIENTES</h2>
        <h1 className="text-center font-exo text-gray-80 text-2xl">134</h1>

        <div className="flex justify-center">
          <ReactApexChart
            options={doughnut.options}
            series={[50, 50]}
            type="donut"
            width={320}
            height={320}
          />
        </div>
      </Card>
    </div>
  );
};
