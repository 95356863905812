import axios from "axios";
import { TOKEN } from "../contexts/UserContext";

export const apiAxios = axios.create({
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

apiAxios.interceptors.request.use(
  request => {
    const token = sessionStorage.getItem(TOKEN);

    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }

    return request;
  },
  error => {
    const status = error.response.status;

    if (status === 403) {
      console.log("Usuário sem premissão.");
    }

    return Promise.reject(error);
  }
);

apiAxios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // console.log(error.response);
    // return Promise.reject(error);
    return error.response;
  }
);
